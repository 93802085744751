import React, { useState, useEffect } from 'react';

import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  Card,
  message, 
  Radio, 
  Input
} from "antd";

import './index.css';

import { QRCode } from 'react-qrcode-logo';

const {Title, Text} = Typography
const Page = () => {
  const [time,setTime] = useState(false)
  const [remark,setRemark] = useState(false)

  return (
      <div className="tv-wellness-survey">

          <div className="tv-wellness-survey-qr">
            <QRCode size={440} value={'line://app/1657440707-7BnLPJLa'} />
          </div>

      </div>
  );


}

export default Page;
