import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  Card,
  message, 
  Avatar,
  Radio, 
  QRCode,
  Input
} from "antd";

import moment from "moment"
import Lottie from 'react-lottie';
import { Link, useParams } from 'react-router-dom'
import { ProForm, ProFormRadio, ProFormTextArea } from '@ant-design/pro-components';
import { CreditCardTwoTone, UserOutlined, CheckOutlined, RightOutlined, LeftOutlined, CalendarTwoTone } from "@ant-design/icons";
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationWait from '../../../../../assets/lottie/block_confirm.json'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'


const {Text,Title} = Typography

const BookingTicket = () => {
  let { id } = useParams();

  const [agree, setAgree] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [ticketEvent, setTicketEvent] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleConfirm,setVisibleConfirm] = useState(false);
  const [step, setStep] = useState(0);
  const [loadingInit,setLoadingInit]= useState(true);
  const [loadingList,setLoadingList]= useState(false);
  const [loadingReferesh,setLoadingRefresh]= useState(false);
  const [loadingSubmit,setLoadingSubmit]= useState(false);
  const [loadingCancel,setLoadingCancel]= useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const [dataTime, setDataTime] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [inuse, setInuse] = useState(false);

  const { authLiff, isLogin, sleep, currencyFormat } = useContext(MainContext);

  useEffect(() => {  
    if(isLogin==true){
      onGetInit()
    }
  }, [isLogin,submitDone]); 

  useEffect(() => {  
    if(isLogin==true){
      onGetList()
    }
  }, [selectedEvent,ticketEvent,isLogin]);  

  // useEffect(() => {  
  //     onGetInit()
  // }, []); 

  // useEffect(() => {  
  //     onGetList()
  // }, [selectedEvent,ticketEvent]); 


  const onGetInit = async(value=false) => {
    setLoadingInit(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/ticket/'+id+'/init', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({}),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onGetList===>',data)
          setTimeout(()=>{
            if(data.status==true){
                setTicketEvent(data.event)
                if(data.found){
                  setAgree(true)
                  setStep(1)
                  setSelectedEvent(data.found)
                  setSelectedSlot(data.found)
                  setSelectedTime(data.found.label)
                  setInuse(data.inuse)
                }
            }else{
              message.info(data.message);
            }
            if(value){
              setVisible(false)
            }
            setLoadingInit(false)
          },2000)
        })
        .catch((error) => {
          console.log(error)
          setLoadingInit(false)
            message.info('line not support');
        });
  }

  const onGetRefresh = async() => {
    setLoadingCancel(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/ticket/'+id+'/init', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({}),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onGetList===>',data)
          setTimeout(()=>{
            if(data.status==true){
                setTicketEvent(data.event)
                if(data.found){
                  setAgree(true)
                  setStep(1)
                  setSelectedEvent(data.found)
                  setSelectedSlot(data.found)
                  setSelectedTime(data.found.label)
                  setInuse(data.inuse)
                }
            }else{
              message.info(data.message);
            }
            setVisible(false)
            setLoadingCancel(false)
          },2000)
        })
        .catch((error) => {
          console.log(error)
          setLoadingCancel(false)
            message.info('line not support');
        });
  }






  const onGetList = async(value) => {
    setLoadingList(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/ticket/'+id+'/list', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({}),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onGetList===>',data)
          setTimeout(()=>{
            if(data.status==true){
                setDataTime(data.data)
            }else{
              message.info(data.message);
            }
            setLoadingList(false)
          },500)
        })
        .catch((error) => {
          console.log(error)
          setLoadingList(false)
            message.info('line not support');
        });
  }

  const onSubmitBook = async() => {
    const accessToken = await authLiff.getAccessToken()
    setLoadingSubmit(true)
    fetch('/api/liff/kuber/event/ticket/'+id+'/book', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              label:selectedTime
            }),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onSubmitBook===>',data)
          setTimeout(()=>{
            setLoadingSubmit(false)
            if(data.status==true){
              setSubmitDone(true)
            }else{
              message.error(data.message);
              onCancelConfirm()
            }
          },1000)
        })
        .catch((error) => {
          console.log(error)
          setLoadingSubmit(false)
            message.info('line not support');
        });
  }

  const onSubmit = () => {
    console.log(selectedTime)
    setVisibleConfirm(true)
  }

  const onCancel = ()=>{
  console.log('on Cancel')
    setVisible(false)
  }

  const onCancelConfirm = ()=>{
    setVisibleConfirm(false)
    if(submitDone){
      setStep(1)
    }
  }

  const RenderPolicy = () => {
    return (
      <div style={{textAlign:'center'}}>
        <Text style={{fontSize:10,margin:0,padding:0,textAlign:'center'}}>
          <strong>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</strong><br />
          <Text style={{fontSize:9,margin:0,padding:0,textAlign:'center'}}>
            {ticketEvent && (<div dangerouslySetInnerHTML={{__html: ticketEvent.policy}}/>)}
          </Text>
        </Text>
      </div>
    )
  }

  const RenderThank = () => {
    return (
      <Text style={{fontSize:10,margin:0,padding:0}}>
        {ticketEvent && (<div dangerouslySetInnerHTML={{__html: ticketEvent.thank}}/>)}
      </Text>
    )
  }

  const RenderPay = () => {
    return selectedSlot && (
      <>
        {selectedSlot.pay_type=='token' && (<><Divider /><Text>จำหน่ายตั๋วในราคา</Text><Flex justify={'flex-start'} align={'center'}>
          <Avatar src={`/img/token/${selectedSlot?.currency}.png`} size={20} className="mr-1"/> 
          <Text>{currencyFormat(selectedSlot.amount,2)} {selectedSlot?.currency}</Text>
          </Flex>
          <br />
          <Text style={{fontSize:10,margin:0,padding:0}}><div>จะไม่มีการ refund ใดๆ หลังจากที่ท่านได้ทำการกดตกลงเพื่อใช้เหรียญในการทำรายการ</div></Text>
          </>
        )}
      </>
    )
  }

  const RenderConsent = () => {
    return (
      <>
        <Flex  vertical={'vertical'} justify={'center'} align={'start'} gap={2} style={{marginBottom:20}}>

          <Title level={4} style={{ textAlign: 'center' }}>เงื่อนไขการเข้าร่วม {ticketEvent.name}</Title>
          {ticketEvent && (<div dangerouslySetInnerHTML={{__html: ticketEvent.condition}}/>)}

        </Flex>
        <Button type="primary" block style={{ background: '#4CAF50', borderColor: '#4CAF50' }} onClick={()=>setAgree(true)}>
            Accept
        </Button>
      </>
    )
  }

  //<LinkAccount liffId={'1657440707-Pdo5v65e'}>
  return (
    <LinkAccount liffId={'1657440707-Pdo5v65e'}>
    <Card>
      <img src={`/img/liff/event/ticket/67d7de80ffc480455d559cde.png`} style={{width:'100%',borderRadius: 20,marginBottom:30 }}/>
        {!loadingInit? (agree? ( step==0? (
          <>
            {/* <Title level={4}><CalendarTwoTone twoToneColor={'#1da57a'}/> {getCurrentMonth()}</Title>*/}

            <Spin spinning={loadingList}>

              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'}>
                {dataTime.map((time) => (
                  <Button
                    key={time._id}
                    block
                    size={'large'}
                    type={selectedTime === time.label? 'primary' : 'default'}
                    disabled={(time.employee_id?.length || 0)==(time.total || 0)}
                    onClick={() => {
                      console.log('time==>',time)
                      setSelectedSlot(time)
                      setSelectedTime(time.label)
                    }}
                  >
                      <Flex justify={'space-between'} align={'center'} >
                        <Text style={selectedTime === time.label? {color:'white'} : {color:'black'}}>{time.label}{selectedTime === time.label && <CheckOutlined />}</Text> <Text style={selectedTime === time.label? {color:'white'} : {color:'black'}}>({time.employee_id?.length || 0}/{time.total})<UserOutlined /></Text>
                      </Flex>
                  </Button>
                ))}
              </Flex>
            </Spin>

            <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20}}>
              <Button onClick={() => onSubmit()} block size="large" disabled={selectedTime==''? true : false} type={selectedTime==''? 'default' : 'primary'}>Booking</Button>
            </Flex>

            <Modal
              open={visibleConfirm}
              onCancel={onCancelConfirm}
              footer={null}
              centered
              closable={true}
            >
              {submitDone==false? (
              <>
                  {loadingSubmit==false? (
                    <Spin spinning={loadingSubmit} delay={500}>
                      <div style={{textAlign:'center'}}>
                        <Title level={4}>ยืนยันการเข้าร่วม</Title>
                        <Text>{ticketEvent.description}</Text>
                        <Divider />
                        <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
                      </div>
                      <RenderPay/>
                      <Divider />
                      <RenderPolicy />
                      <Divider />
                      <Button type="primary" block style={{ background: '#4CAF50', borderColor: '#4CAF50' }} onClick={onSubmitBook}>
                        Confirm
                      </Button>
                    </Spin>
                  ) : (
                   <Result
                      icon={<Lottie options={{
                          animationData: animationWait,
                          loop: true,
                          autoplay: true,
                        }} height={200} width={200}/>}
                      title="Waiting"
                      subTitle="กำลังรอ confirm ธุรกรรม"
                      style={{padding:0}}
                    />
                  )}
              </>
              ):(
              <>
                <Result
                  title={(<>ทำการ Booking สำเร็จ</>)}
                  subTitle={(<Text>ท่านได้ทำการ Booking เพื่อเข้าร่วม {ticketEvent.name} สำเร็จ</Text>)}
                  icon={<Lottie options={{
                    animationData: animationData,
                    loop: false,
                    autoplay: true,
                  }} height={200} width={200}/>}
                  extra={[
                    <Button type="primary" block size="large" onClick={onCancelConfirm}>
                      Close
                    </Button>
                  ]}
                  style={{padding:0}}
                />
                <Divider />
                <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
                <Divider />
                <RenderPolicy />
              </>
              )}
            </Modal>
          </>
        ) : (
          <>
           {inuse? (
            <>
              <Result
                title={(<>Joined Success</>)}
                icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
                style={{padding:0}}
              />
              <div style={{ textAlign: 'center'}}>
                <Text>ท่านได้ทำการ Check-in เข้าร่วม {ticketEvent.description} เรียบร้อยแล้ว</Text>
              </div>
            </>
            ): (
            <>
              <Result
                title={(<>การจองปัจจุบัน</>)}
                icon={<CalendarTwoTone height={200} width={200} twoToneColor={'#1da57a'}/>}
                style={{padding:0}}
              />
              <div style={{ textAlign: 'center'}}>
                <Text>ท่านได้ทำการจองเข้าร่วม {ticketEvent.description}</Text>
              </div>
            </>
            )}
            <Divider />
            <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
            <Divider />
            {inuse? (
              <>
              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20,marginBottom:20}}>
                <Button block size="large" type={'primary'}>ท่านได้ Check-in เข้าร่วมกิจกรรมแล้ว</Button>
              </Flex>
              <RenderThank/>
              </>
            ): (
              <>
              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20,marginBottom:20}}>
                <Button onClick={()=>{
                  setVisible(true)
                }} block size="large" type={'text'}  type={'primary'}>แสดง QR Checkin</Button>
              </Flex>
              <RenderPolicy />
              </>
            )}
            
            <Modal
              open={visible}
              onCancel={onCancel}
              footer={null}
              centered
              closable={false}
            >
            <Spin spinning={loadingCancel} delay={500}>

              <Result
                icon={<div style={{padding:0,display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center',paddingTop:20}}>
                  <QRCode
                    errorLevel="H"
                    size={250}
                    value={selectedEvent.qr}
                  />
                </div>}
                title={'QR Checkin'}
                subTitle={`กรุณาแสดง QR เพื่อทำการ check in กับทาง เจ้าหน้าที่ เพื่อเข้าร่วมกิจกรรม ${ticketEvent.name}`}
                style={{padding:0}}
                extra={
                  <Button onClick={()=>{
                    onGetRefresh(true)
                  }} block size="large" type="primary">
                    Close
                  </Button>
                }
              >
                <Text>{selectedTime}</Text>
              </Result>

              </Spin>
            </Modal>
        </>
      )) : (<RenderConsent />)) : (<></>)}
      </Card>
     </LinkAccount>
    )
};


export default BookingTicket;
