import { useRef, useState, useEffect, useContext } from 'react';
import { ConfigProvider, theme} from 'antd';
import { Outlet } from "react-router-dom";
import { MainContext } from "../contexts/main";


import enUSIntl from "antd/lib/locale/en_US";
import theme_token from '../themes/index';
const { defaultAlgorithm, darkAlgorithm } = theme;

export default (props) => {

  return (
    <ConfigProvider 
      locale={enUSIntl}
      theme={{
        algorithm: defaultAlgorithm,
        token: theme_token.dark
      }}
    >
        <Outlet />
    </ConfigProvider>
  )
};