import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  message, 
  Radio, 
  Form, 
  Card,
  Rate, 
  Space,
  Input
} from "antd";
import Lottie from 'react-lottie';
import { ProForm, ProFormRadio, ProFormTextArea } from '@ant-design/pro-components';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { SmileOutlined, MehOutlined, FrownOutlined  } from "@ant-design/icons";

import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationScand from '../../../../../assets/lottie/scand.json'
const {Text,Title} = Typography
const { TextArea } = Input;
const BookingSurvey = () => {
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(0);
  const [info, setInfo] = useState(false);

  const { authLiff, isLogin, sleep } = useContext(MainContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code') || '-';
  const navigate = useNavigate();

    // useEffect(() => {  
    //   // if(isLogin==true){
    //     if(code.length>=10){
    //      queryCode(code);
    //     }else{
    //       setStep(1)
    //     }
    //   // }
    // }, []);  

    useEffect(() => {  
      if(isLogin==true){
        if(code.length>=10){
         queryCode(code);
        }else{
          setStep(1)
        }
      }
    }, [isLogin]);  

    const queryCode = async(value) => {
      const qr_code = value.split('?code=').length==2? value.split('?code=')[1] : value
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/calendar/calendar/checkin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                code:qr_code,
                sv:authLiff.getContext().type
              }),
          })
          .then((response) => response.json())
          .then((data) => {
                  setStep(data.step) //2,3
                  setInfo(data.message)
                  setLoading(false)
             
          })
          .catch((error) => {
              setStep(3)
              setLoading(false)
          });
    }

    const onScanAction = async() => {
      try {
          setLoading(true)
          authLiff
            .scanCodeV2()
            .then(async(result) => {
                  if(result && result.value){
                    queryCode(result.value)
                  }else{
                    setLoading(false)
                  }
            }).catch((error) => {
                setLoading(false)
                message.info('line app not support camera');
            });
        
      } catch(error) {
        setLoading(false)
      }
    }


    const RenderScan = () => {
      return (
        <Card>
          <Result
            icon={<Lottie options={{
                animationData: animationScand,
                loop: false,
                autoplay: true,
              }} height={200} width={200}/>}
            title={'Scan QR'}
            subTitle={"กรุณาทำการกดที่ปุ่ม Scan Qr เพื่อ checkin"}
            style={{padding:0}}
            extra={[
              <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
                Scan Qr
              </Button>
            ]}
          />
        </Card>
      )
    }



    const RenderFalse = ()=>{
        return (
            <Card>
              <div style={{height:100}}></div>
              <Result
                status="error"
                title="Checking ไม่สำเร็จ"
                subTitle={info}
                style={{padding:0}}
              />
              <div style={{height:100}}></div>
            </Card>
        )
    }



    const RenderSuccess = () => {
        return (
            <Card>
              <Result
                status="success"
                title="ท่านได้ทำการ Checking สำเร็จ"
                subTitle={info}
                icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
                style={{padding:0}}
              />
              <div style={{height:100}}></div>
            </Card>
        )
    }


  //<LinkAccount liffId={'1657440707-DWkapKaG'}>
  return (
    <LinkAccount liffId={'1657440707-DWkapKaG'}>
    <Spin spinning={loading}>
        {step==0? (<Card><div style={{height:100}}></div></Card>) : (step==1? <RenderScan/> : (step==2? <RenderSuccess /> : <RenderFalse/>))}
    </Spin>
    </LinkAccount>
  );
};


export default BookingSurvey;
