import React, { useState, useEffect } from 'react';

import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  Card,
  message, 
  Radio, 
  Input
} from "antd";

import './index.css';

import { QRCode } from 'react-qrcode-logo';

const {Title, Text} = Typography
const Page = () => {
  const [time,setTime] = useState(false)
  const [total,setTotal] = useState(false)
  const [remark,setRemark] = useState(false)

  const callApi = () => {
    fetch("/api/liff/kuber/event/calendar/calendar/queue")
      .then((res) => res.json())
      .then((res) => {
        setTime(res.time)
        setTotal(res.total)
        setRemark(res.data)
      })
  }

  useEffect(() => {
    callApi()
    const interval = setInterval(() => {
      callApi()
    }, 5000);
    return () => clearInterval(interval);
  }, []);



  return (
      <div className="tv-wellness">

          <div className="tv-wellness-time">
            {time? time : '-'}
          </div>
          <div className="tv-wellness-total">
            {total? total : '-'}
          </div>
          <div className="tv-wellness-qr">
            <QRCode size={540} value={'line://app/1657440707-DWkapKaG?code=677793cd601836d39c4ddff5'} />
          </div>
          {remark && (<div className="tv-wellness-remark">
          {remark.map(item=>{
            return (<>{item}<br /></>)
          })}
          </div>)}

      </div>
  );


}

export default Page;
