import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  Card,
  message, 
  Radio, 
  Input
} from "antd";

import moment from "moment"
import Lottie from 'react-lottie';
import { ProForm, ProFormRadio, ProFormTextArea } from '@ant-design/pro-components';
import { UserOutlined, CheckOutlined, RightOutlined, LeftOutlined, CalendarTwoTone } from "@ant-design/icons";
import * as animationData from '../../../../../assets/lottie/done.json'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'


const {Text,Title} = Typography

const BookingCalendar = () => {
  const [agree, setAgree] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleConfirm,setVisibleConfirm] = useState(false);
  const [step, setStep] = useState(0);
  const [loadingInit,setLoadingInit]= useState(true);
  const [loadingList,setLoadingList]= useState(false);
  const [loadingSubmit,setLoadingSubmit]= useState(false);
  const [loadingCancel,setLoadingCancel]= useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const [dataTime, setDataTime] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [inuse, setInuse] = useState(false);

  const { authLiff, isLogin, sleep } = useContext(MainContext);

  useEffect(() => {  
    if(isLogin==true){
      onGetInit()
    }
  }, [isLogin]); 

  useEffect(() => {  
    if(isLogin==true){
      onGetList()
    }
  }, [selectedDay,isLogin]);  

  const onGetInit = async(value) => {
    setLoadingInit(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/calendar/calendar/init', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              date:moment(selectedDay).format('YYYY-MM-DD'),
            }),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onGetList===>',data)
          setTimeout(()=>{
            if(data.status==true){
                if(data.found){
                  setAgree(true)
                  setStep(1)
                  setSelectedDay(new Date(data.found.date))
                  setSelectedTime(data.found.label)
                  setInuse(data.inuse)
                }
            }else{
              message.info(data.message);
            }
            setLoadingInit(false)
          },500)
        })
        .catch((error) => {
          console.log(error)
          setLoadingInit(false)
            message.info('line not support');
        });
  }


  const onGetList = async(value) => {
    setLoadingList(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/calendar/calendar/list', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              date:moment(selectedDay).format('YYYY-MM-DD'),
            }),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onGetList===>',data)
          setTimeout(()=>{
            if(data.status==true){
                setDataTime(data.data)
            }else{
              message.info(data.message);
            }
            setLoadingList(false)
          },500)
        })
        .catch((error) => {
          console.log(error)
          setLoadingList(false)
            message.info('line not support');
        });
  }

  const onSubmitBook = async() => {
    const accessToken = await authLiff.getAccessToken()
    setLoadingSubmit(true)
    fetch('/api/liff/kuber/event/calendar/calendar/book', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              book_date:moment(selectedDay).format('YYYY-MM-DD'),
              book_time:selectedTime
            }),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onSubmitBook===>',data)
          setTimeout(()=>{
            setLoadingSubmit(false)
            if(data.status==true){
              setSubmitDone(true)
            }else{
              message.error(data.message);
              onCancelConfirm()
            }
          },1000)
        })
        .catch((error) => {
          console.log(error)
          setLoadingSubmit(false)
            message.info('line not support');
        });
  }

  const onSubmitCancel = async(value) => {
    setLoadingCancel(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/calendar/calendar/cancel', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              ...value,
              book_date:moment(selectedDay).format('YYYY-MM-DD'),
              book_time:selectedTime
            }),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onSubmitCancel===>',data)
          setTimeout(()=>{
            
            if(data.status==true){
              onGetList()
              setTimeout(()=>{
                setSubmitDone(false)
                onCancel()
                setStep(0)
                setLoadingCancel(false)
              },2000)
            }else{
              message.error(data.message);
            }
          },1000)
        })
        .catch((error) => {
            setTimeout(()=>{
              setLoadingCancel(false)
            },1000)
            message.info('line not support');
        });
  }


  const onSubmit = () => {
    console.log(selectedTime)
    setVisibleConfirm(true)
  }

  // Generate days dynamically based on currentDate
  const getWeekDays = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay() + 1); // Monday as the first day of the week

    return Array.from({ length: 7 }).map((_, i) => {
      const newDate = new Date(startOfWeek);
      newDate.setDate(startOfWeek.getDate() + i);
      return newDate;
    });
  };

  const days = getWeekDays(currentDate);

  // Function to change the week
  const changeWeek = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + direction * 7);
    setCurrentDate(newDate);
  };

  // Function to reset to today
  const goToToday = () => {
    const today = new Date();
    setCurrentDate(today);
    setSelectedDay(today);
  };

  // Get current month name
  const getCurrentMonth = () => {
    return currentDate.toLocaleDateString("en-GB", { month: "long", year: "numeric" });
  };

  const onCancel = ()=>{
  console.log('on Cancel')
    setVisible(false)
  }

  const onCancelConfirm = ()=>{
    setVisibleConfirm(false)
    if(submitDone){
      setStep(1)
    }
  }


  const RenderPolicy = () => {
    return (
      <Text style={{fontSize:10,margin:0,padding:0}}>
        <strong>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</strong><br />
        <Text style={{fontSize:9,margin:0,padding:0}}>
          ในการเข้ารับบริการ พนักงานรับทราบว่าบริษัท บิทคับ แคปปิตอล กรุ๊ป โฮลดิ้งส์ จำกัด (“บริษัทฯ”) เก็บรวบรวมข้อมูลส่วนบุคคลของพนักงานสำหรับการลงทะเบียนเข้ารับบริการ ทั้งนี้ 
          บริษัทฯ ขอแจ้งให้ทราบว่า บริษัทฯ ไม่มีการเก็บรวบรวมข้อมูลสุขภาพของพนักงาน 
          ในกรณีที่พนักงานมีข้อสงสัยเพิ่มเติมเกี่ยวการเก็บรวบรวมข้อมูลส่วนบุคคล 
          สามารถติดต่อ People Team ได้ที่ @Aui.Supattra หรือ @Ging.Korakod
        </Text>
      </Text>
    )
  }

  const RenderThank = () => {
    return (
      <Text style={{fontSize:10,margin:0,padding:0}}>
        ขอขอบคุณที่เข้ารับบริการห้อง Wellness Room หวังว่าการนวดจะช่วยให้ท่านรู้สึกผ่อนคลายและบรรเทาอาการปวดเมื่อยได้อย่างดีนะคะ
      </Text>
    )
  }




  const RenderConsent = () => {
    return (
      
      <Card>
        <Flex  vertical={'vertical'} justify={'center'} align={'start'} gap={2} style={{marginBottom:20}}>


          <Title level={4} style={{ textAlign: 'center' }}>เงื่อนไขการรับบริการ</Title>
            <ul>
              <li>- Bitkuber ต้องจองคิวล่วงหน้าก่อนรับบริการจาก<br />ผู้บริการนวด ผ่าน Line@kuber เท่านั้น(งดรับคิว walk-in หน้าห้อง)</li>
              <li>- Bitkuber สามารถรับบริการได้ท่านละ 1 ครั้ง/เดือน และไม่สามารถโอนสิทธิให้แก่กัน หรือจองคิวแทนกันได้</li>
              <li>- หากต้องการยกเลิก หรือเปลี่ยนแปลงคิวที่รับบริการ <br />โปรดยกเลิกล่วงหน้า 1 ชั่วโมง ก่อนถึงคิวที่ได้มีการจองไว้ (สำหรับ Bitkuber ที่ยกเลิกคิว สามารถจองได้ในรอบถัดไป ภายในเดือน)</li>
              <li>- โปรดสแกน QR Code ที่หน้าห้องเพื่อยืนยันการ<br />เข้ารับบริการ และสแกน QR Code feedback form <br />หลังรับบริการเสร็จ</li>
              <li>- ทาง People Team ขอสงวนสิทธิในการเปลี่ยนแปลง ยกเลิก หรือแก้ไข เงื่อนไขการรับบริการ</li>
            </ul>
          <Title level={4} style={{ textAlign: 'center',marginTop:20 }}>ข้อห้ามในการรับบริการ</Title>
            <ul>
              <li>- อุณหภูมิร่างกายเกินกว่า 37.5C มีไข้ ไอ หรือเป็นหวัด</li>
              <li>- กระดูกหักร้าว ที่ยังไม่ติดดี</li>
              <li>- ความดันสูงกว่า 140/100</li>
              <li>- มีโรคประจำตัวที่แพทย์สั่งห้ามนวด เช่น เป็นโรคไต มะเร็ง โรคหัวใจ และโรคเบาหวาน รวมถึงได้รับการหลังผ่าตัดน้อยกว่า 45 วัน</li>
              <li>- มีอาการหน้ามืด ใจสั่น ปวดหัวคลื่นไส้ อาเจียน</li>
              <li>- ทานยาที่ส่งต่อความผิดปกติของการแข็งตัวของเลือด</li>
              <li>- ขอความร่วมมือพนักงานทุกท่านปฏิบัติตามเงื่อนไข<br />และข้อห้ามอย่างเคร่งครัด เพื่อความปลอดภัยของตัวท่าน</li>
            </ul>


        </Flex>
        <Button type="primary" block style={{ background: '#4CAF50', borderColor: '#4CAF50' }} onClick={()=>setAgree(true)}>
            Accept
        </Button>
      </Card>

    )
  }



  //<LinkAccount liffId={'1657440707-vMWzPXzV'}>
  return (
    <>
    <LinkAccount liffId={'1657440707-vMWzPXzV'}>
    {!loadingInit? (agree? ( step==0? (
          <Card>
            {/* <Title level={4}><CalendarTwoTone twoToneColor={'#1da57a'}/> {getCurrentMonth()}</Title>*/}

            {/* Navigation Buttons */}
            <Flex justify={'space-between'} align={'center'} gap={2} style={{marginBottom:20}}>
              <Button onClick={() => changeWeek(-1)} type="text" icon={<LeftOutlined/>}></Button>
              <Button onClick={goToToday} type="text">Today</Button>
              <Button onClick={() => changeWeek(1)} type="text" icon={<RightOutlined/>}></Button>
            </Flex>

            {/* Calendar Days */}
            <Flex justify={'center'} align={'center'} gap={2} style={{marginBottom:20}}>
              {days.map((d,index) => (
                <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} key={'list-'+index}>
                  <label>{d.toLocaleDateString("en-US", { weekday: "short" })}</label>
                  <Button key={d.toISOString()} style={{width:43,height:43,fontWeight:700,fontSize:18}} onClick={() => {
                      setSelectedDay(d)
                      setSelectedTime('')
                      console.log('day==>',d)
                  }} shape="circle" type={selectedDay.toDateString() === d.toDateString()? 'primary' : 'text'}>
                      {d.getDate()}
                  </Button>
                </Flex>
              ))}
            </Flex>
            <Spin spinning={loadingList}>
              <Flex justify={'center'} align={'center'} style={{marginBottom:20}}>
                {selectedDay.toLocaleDateString("en-GB", { weekday: "long", day: "numeric", month: "long", year: "numeric"})}
              </Flex>
              

              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'}>
                {dataTime.map((time) => (
                  <Button
                    key={time._id}
                    block
                    size={'large'}
                    type={selectedTime === time.label? 'primary' : 'default'}
                    disabled={(time.employee_id?.length || 0)==(time.total || 0)}
                    onClick={() => {
                      console.log('time==>',time)
                        setSelectedTime(time.label)
                    }}
                  >
                      <Flex justify={'space-between'} align={'center'} >
                        <Text style={selectedTime === time.label? {color:'white'} : {color:'black'}}>{time.label}{selectedTime === time.label && <CheckOutlined />}</Text> <Text style={selectedTime === time.label? {color:'white'} : {color:'black'}}>({time.employee_id?.length || 0}/{time.total})<UserOutlined /></Text>
                      </Flex>
                  </Button>
                ))}
              </Flex>
            </Spin>

            <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20}}>
              <Button onClick={() => onSubmit()} block size="large" disabled={selectedTime==''? true : false} type={selectedTime==''? 'default' : 'primary'}>Booking</Button>
            </Flex>

       {/*     <div style={{textAlign:'left',marginTop:20}}>
                <Text>เงื่อนไขการรับบริการ ทดลองการทำงานของพนักงาน</Text>
                <ul>
                    <li><Text>เริ่มวันที่ 5 มีนาคม  ฟรี 1 เดือน</Text></li>
                    <li><Text>ยกเลิกล่วงหน้า 3 วันทำการ </Text></li>
                    <li><Text>สแกน QR Code หลังรับบริการ + feed back </Text></li>
                </ul>
            </div>*/}
            <Modal
              open={visibleConfirm}
              onCancel={onCancelConfirm}
              footer={null}
              centered
              closable={true}
            >
              {submitDone==false? (
                <Spin spinning={loadingSubmit} delay={500}>
                  <div style={{textAlign:'center'}}>
                    <Title level={4}>ยืนยันการจอง</Title>
                    <Text>
                      เพื่อเข้ารับบริการ นวด คอ บ่า ไหล่ โดยผู้บกพร่องทางการมองเห็น
                    </Text>
                    <Divider />
                    <Title level={5} style={{textAlign:'center',margin:0,padding:0}}>{moment(selectedDay).format('YYYY-MM-DD')}</Title>
                    <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
                  </div>
                  <Divider />
                  <RenderPolicy />
                  <Divider />
                  <Button type="primary" block style={{ background: '#4CAF50', borderColor: '#4CAF50' }} onClick={onSubmitBook}>
                    Confirm
                  </Button>
                </Spin>
              ):(
              <>
                <Result
                  title={(<>ทำการจองสำเร็จ</>)}
                  subTitle={(<Text>ท่านได้ทำการจอง  เพื่อเข้ารับบริการ นวด คอ บ่า ไหล่ <br />โดยผู้บกพร่องทางการมองเห็น สำเร็จ</Text>)}
                  icon={<Lottie options={{
                    animationData: animationData,
                    loop: false,
                    autoplay: true,
                  }} height={200} width={200}/>}
                  extra={[
                    <Button type="primary" block size="large" onClick={onCancelConfirm}>
                      Close
                    </Button>
                  ]}
                  style={{padding:0}}
                />
                <Divider />
                <Title level={5} style={{textAlign:'center',margin:0,padding:0}}>{moment(selectedDay).format('YYYY-MM-DD')}</Title>
                <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
                <Divider />
                <RenderPolicy />
              </>
              )}
            </Modal>
          </Card>
        ) : (
          <Card>
            <Result
              title={(<>การจองปัจจุบัน</>)}
              icon={<CalendarTwoTone height={200} width={200} twoToneColor={'#1da57a'}/>}
              style={{padding:0}}
            />
            <div style={{ textAlign: 'center'}}>
              <Text>ท่านได้ทำการจอง เพื่อเข้ารับบริการ นวด คอ บ่า ไหล่ <br />โดยผู้บกพร่องทางการมองเห็น <br />ในวันที่</Text>
            </div>
            <Divider />
            <Title level={5} style={{textAlign:'center',margin:0,padding:0}}>{moment(selectedDay).format('YYYY-MM-DD')}</Title>
            <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
            <Divider />
            {inuse? (
              <>
              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20,marginBottom:20}}>
                <Button block size="large" type={'primary'}>ท่านได้เข้ารับบริการแล้ว</Button>
              </Flex>
              <RenderThank/>
              </>
            ): (
              <>
              <Flex justify={'center'} align={'center'} vertical={'vertical'} gap={'small'} style={{marginTop:20,marginBottom:20}}>
                <Button onClick={()=>{
                  setVisible(true)
                }} block size="large" type={'text'} danger>ยกเลิกการจอง</Button>
              </Flex>
              <RenderPolicy />
              </>
            )}
            
            <Modal
              open={visible}
              onCancel={onCancel}
              footer={null}
              centered
              closable={true}
            >
            <Spin spinning={loadingCancel} delay={500}>
              <div style={{textAlign:'center'}}>
                <Title level={4}>ขอยกเลิกการจอง</Title>
                <Text>
                  เพื่อเข้ารับบริการ นวด คอ บ่า ไหล่ <br />โดยผู้บกพร่องทางการมองเห็น
                </Text>
              </div>
              <Divider />
              <Title level={5} style={{textAlign:'center',margin:0,padding:0}}>{moment(selectedDay).format('YYYY-MM-DD')}</Title>
              <Title level={4} style={{textAlign:'center',margin:0,padding:0}}>{selectedTime}</Title>
              <Divider />
              <ProForm
                layout="vertical"
                onFinish={onSubmitCancel}
                submitter={{
                  searchConfig: {
                    submitText: 'ยืนยัน',
                  },
                  render: (props, doms) => {
                    return [
                      <Button
                        block
                        danger
                        key="submit"
                        onClick={() => props.form?.submit?.()}
                      >
                        ยืนยัน
                      </Button>,
                    ];
                  },
                }}
              >
                <ProFormRadio.Group
                    fieldProps={{
                        style:{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                        },
                        onChange: (e) => setSelectedReason(e.target.value)
                    }}
                    name="reason"
                    label="โปรดระบุเหตุผล*"
                    options={[
                        { label: 'ติดประชุม / ติดธุระกะทันหัน', value: 'meeting' },
                        { label: 'ลาป่วย', value: 'sick' },
                        { label: 'อื่น ๆ', value: 'other' },
                    ]}
                    rules={[{ required: true, message: 'โปรดเลือกเหตุผล' }]}
                />

                {selectedReason=='other' && (<ProFormTextArea 
                  name="comment" 
                  placeholder="กรุณาระบุเหตุผลเพิ่มเติม" 
                  rules={[{ required: true, message: 'โปรดระบุเหตุผลเพิ่มเติม' }]}
                />)}
              </ProForm>
              </Spin>
            </Modal>
        </Card>
      )) : (<RenderConsent />)) : (<></>)}
     </LinkAccount>
    </>
    )
};


export default BookingCalendar;
