import React, { useState, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Spin,
  Modal,
  Flex,
  message, 
  Radio, 
  Form, 
  Card,
  Rate, 
  Space,
  Input
} from "antd";
import Lottie from 'react-lottie';
import { ProForm, ProFormRadio, ProFormTextArea } from '@ant-design/pro-components';

import { SmileOutlined, MehOutlined, FrownOutlined  } from "@ant-design/icons";

import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
const {Text,Title} = Typography
const { TextArea } = Input;
const BookingSurvey = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);

  const { authLiff, isLogin, sleep } = useContext(MainContext);

  const handleSubmit = async(values) => {
    console.log('Survey responses:', values);
    const accessToken = await authLiff.getAccessToken()
    setLoading(true)
    fetch('/api/liff/kuber/event/calendar/survey/submit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(values),
        })
        .then((response) => response.json())
        .then((data) => {
          console.log('onSubmitBook===>',data)
          setTimeout(()=>{
            setLoading(false)
            setStep(1)
          },1000)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
            message.info('line not support');
        });
  }

    const customIcons = {
      1: <FrownOutlined style={{ fontSize: 44 }} />,
      2: <MehOutlined style={{ fontSize: 44 }} />,
      3: <MehOutlined style={{ fontSize: 44 }} />,
      4: <SmileOutlined style={{ fontSize: 44 }} />,
      5: <SmileOutlined style={{ fontSize: 44 }} />,
    };


    const RenderForm = ()=>{
        return (
            <Card>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Title level={3}>แบบสอบถามความพึงใจ</Title>

              <Form layout="vertical" onFinish={handleSubmit}>

                <div style={{textAlign:'center',marginBottom:20,marginTop:20}}>
                  <Text strong>คุณพึงพอใจกับบริการนวดของเราโดยรวมแค่ไหน?</Text>
                  <br />
                  <Text type="secondary">
                    (How satisfied are you with the overall massage service?)
                  </Text>
                </div>
                <Form.Item
                  name="satisfaction"
                  rules={[{ required: true, message: 'กรุณาให้คะแนน' }]}
                >
                  <Rate character={({ index }) => customIcons[index + 1]} count={5} />
                </Form.Item>

                {/* Question 2 */}
                <div style={{textAlign:'center',marginBottom:20,marginTop:20}}>
                  <Text strong>ระดับความผ่อนคลายหลังจากได้รับการนวดเป็นอย่างไร?</Text>
                  <br />
                  <Text type="secondary">
                    (How relaxed did you feel after the massage?)
                  </Text>
                </div>
                <Form.Item
                  name="relaxation"
                  rules={[{ required: true, message: 'กรุณาให้คะแนน' }]}
                >
                  <Rate character={({ index }) => customIcons[index + 1]} count={5} />
                </Form.Item>

                {/* Question 3 */}
                <div style={{textAlign:'center',marginBottom:20,marginTop:20}}>
                  <Text strong>บรรยากาศโดยรวมทำให้คุณรู้สึกผ่อนคลายหรือไม่?</Text>
                  <br />
                  <Text type="secondary">
                    (Did the overall ambiance help you relax?)
                  </Text>
                </div>
                <Form.Item
                  name="ambiance"
                  rules={[{ required: true, message: 'กรุณาให้คะแนน' }]}
                >
                  <Rate character={({ index }) => customIcons[index + 1]} count={5} />
                </Form.Item>


                <div style={{textAlign:'left',marginBottom:20,marginTop:20}}>
                  <Text strong>ข้อเสนอแนะเพิ่มเติม</Text>
                </div>
                <Form.Item
                  name="comment"
                  rules={[{ required: true, message: 'กรุณาให้ข้อเสนอแนะเพิ่มเติม' }]}
                >
                  <TextArea rows={4} />
                </Form.Item>


                <Divider/>
                {/* Submit Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" size={'large'} block style={{ background: '#4CAF50', borderColor: '#4CAF50' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
        </Card>
        )
    }



    const RenderRes = () => {
        return (
            <Card>
            <Result
              title={(<>Your feedback means so much to us!</>)}
              subTitle={'Thank you for your support, and we hope to welcome you back for more relaxation! '}
              icon={<Lottie options={{
                animationData: animationData,
                loop: false,
                autoplay: true,
              }} height={200} width={200}/>}
              style={{padding:0}}
            />
            <div style={{height:100}}></div>
            </Card>
        )
    }


  //<LinkAccount liffId={'1657440707-7BnLPJLa'}>
  return (
    <LinkAccount liffId={'1657440707-7BnLPJLa'}>
    <Spin spinning={loading}>
        {step==0? <RenderForm/> : <RenderRes />}
    </Spin>
    </LinkAccount>
  );
};


export default BookingSurvey;
