import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  Result,
  QRCode,
  Spin,
  Space,
  theme,
  message,
  ConfigProvider,
  Descriptions
} from "antd";

import { PhoneOutlined, ZoomInOutlined, ZoomOutOutlined, CloseOutlined, CarOutlined, AlertOutlined, ScanOutlined, HomeOutlined, CalendarOutlined, EnvironmentOutlined, ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx';
import theme_token from '../../../../../themes/index';


const { defaultAlgorithm, darkAlgorithm } = theme;

const { Title, Text, Paragraph } = Typography;
const PageLiffKuberStaffMenu = () => {  
    const [ loading, setLoading ] = useState(false);
    const [ view, setView ] = useState(false);
    const [ dataQr, setDataQr ] = useState(false);
    const [ dataRoommate, setRoommateData ] = useState(false);  
    const [ dataAgenda, setAgendaData ] = useState(false);
    const [ dataJourney, setJourneyData ] = useState(false);
    const [ dataChecklist, setChecklistData ] = useState(false);
    const [ dataDodont, setDodontData ] = useState(false);
    const [ dataBus, setBusData ] = useState(false);
    const [ dataEmergency, setEmergencyData ] = useState(false);

    const { authLiff, authData, isLogin } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
        setLoading(false)
      }
    }, [isLogin]);  
    const cards = [
      {
        img:'/img/liff/staff/menu_1.png',
        name:'people color',
        link:'https://liff.line.me/1657440707-Pdo5v65e',
        status:true
      },
      {
        img:'/img/liff/staff/menu_2.png',
        name:'wellness',
        link:'https://liff.line.me/1657440707-vMWzPXzV',
        status:true
      },
      {
        img:'/img/liff/staff/menu_3.png',
        name:'well-being',
        link:'https://liff.line.me/1657440707-Lm4VW3VJ',
        status:true
      }
    ]

    const RenderViewMenu = () => {
      return view==false && (
          <Row gutter={[16, 16]} style={{ padding: "20px" }}>
            {cards.filter(item=>item.status==true).map((card, index) => (
              <Col key={index}>
                <a href={card.link}><img src={card.img} style={{width:'90%',margin:'0 auto',cursor:'pointer'}} /></a>
              </Col>
            ))}
          </Row>
      )
    }

    // <LinkAccount liffId={'1657440707-rLyGk6G0'}>
    return (
        <LinkAccount liffId={'1657440707-rLyGk6G0'}>
          <Spin spinning={loading} delay={500}>
            <div style={{backgroundColor: '#00AA4F',backgroundImage:'url(/img/liff/staff/kuber-bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
            <div style={{backgroundImage:'url(/img/liff/staff/kuber-element.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'bottom',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
              <Row gutter={[0, 0]} >
                <Col span={24} className="text-center">
                  <img src={"/img/liff/staff/kuber-header.png"} style={{width:'100%', maxWidth:1000, margin:'0 auto 0'}}/>
                </Col>
                <Col span={24}>
                    <ConfigProvider
                      dark={true}
                      theme={{
                        algorithm: darkAlgorithm,
                        token: theme_token.dark
                      }}
                    >
                      <RenderViewMenu />
                    </ConfigProvider>
                </Col>
              </Row>
            </div>
            </div>

          </Spin>
        </LinkAccount>
    ); 
};

export default PageLiffKuberStaffMenu;

